import React from "react";

import { NodeModel } from "@minoru/react-dnd-treeview";

import styled from "styled-components";
import { StructureInfo } from "../../interface/Structure.interface";
import { ReactComponent as Arrow } from "../../../../../../assets/image/icon_arrow_structure.svg";
import { ReactComponent as Del } from "../../../../../../assets/image/ic_chip_delete.svg";

type Props = {
  isEditable?: boolean;
  node: NodeModel<StructureInfo>;
  depth: number;
  isOpen: boolean;
  isSelected: boolean;
  hasChildren: boolean;
  onToggle: (id: NodeModel["id"]) => void;
  onSelect: (node: NodeModel<StructureInfo>) => void;
  onDelete?: (node: NodeModel<StructureInfo>) => void;
};

export const CustomNode: React.FC<Props> = (props) => {
  const {
    isEditable,
    node,
    hasChildren,
    depth,
    isOpen,
    isSelected,
    onToggle,
    onSelect,
    onDelete,
  } = props;
  const indent = depth * 24;

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    onToggle(node.id);
  };

  const handleSelect = () => {
    console.log(node);
    onSelect(node);
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete && onDelete(node);
  };

  return (
    <Container
      className={`tree-node ${isSelected ? "isSelected" : ""}`}
      style={{ paddingInlineStart: indent }}
      onClick={handleSelect}
    >
      <FlexRow>
        <ExpandIconWrapper className={`${isOpen ? "isOpen" : ""}`}>
          {hasChildren && (
            <div className="node__icon" onClick={handleToggle}>
              <Arrow />
            </div>
          )}
        </ExpandIconWrapper>
        <div className={"body-14-rg labelGridItem"}>{`${node.text} (${
          node.data?.userCount || 0
        })`}</div>
      </FlexRow>
      {isEditable && !node.data?.userCount && !hasChildren && (
        <div className="node__icon" onClick={handleDelete}>
          <Del />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 40px;
  padding-inline-end: 8px;

  cursor: pointer;

  .labelGridItem {
    padding-inline-start: 8px;
  }

  .node__icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      background-color: var(--hover-200);
    }
  }

  &.isSelected {
    color: var(--primary-purple);
    path {
      fill: var(--primary-purple);
    }
  }
`;

const ExpandIconWrapper = styled.div`
  align-items: center;
  font-size: 0;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transition: transform linear 0.1s;
  transform: rotate(0deg);

  &.isOpen {
    transform: rotate(90deg);
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
