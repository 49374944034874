import React, { forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  Tree,
  NodeModel,
  DndProvider,
  MultiBackend,
  getBackendOptions,
  getDescendants,
} from "@minoru/react-dnd-treeview";

import { toast } from "react-toastify";
import { StructureInfo } from "../interface/Structure.interface";
import { Placeholder } from "../components/treeview/Placeholder";
import { CustomDragPreview } from "../components/treeview/CustomDragPreview";
import { CustomNode } from "../components/treeview/CustomNode";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { InputBox } from "../../../../../components-2.0v/input-box/InputBox";
import { DefaultButton } from "lattice_core";

interface Props {
  structureData: NodeModel<StructureInfo>[];
  onSubmit: (newData: NodeModel<StructureInfo>[]) => void;
}

const getLastId = (treeData: NodeModel[]) => {
  const reversedArray = [...treeData].sort((a, b) => {
    if (a.id < b.id) {
      return 1;
    } else if (a.id > b.id) {
      return -1;
    }

    return 0;
  });

  if (reversedArray.length > 0) {
    return reversedArray[0].id;
  }
  return 0;
};

const StructureModifyModal: React.FC<Props> = (props) => {
  const { structureData, onSubmit } = props;
  const MAX_DEPTH = 2;

  const structureDataClone = useRef<NodeModel<StructureInfo>[]>([]);
  const [treeData, setTreeData] = useState<NodeModel<StructureInfo>[]>([]);
  const [selectedNode, setSelectedNode] = useState<string | number | undefined>(
    undefined
  );

  const [newStructueName, updateNewStructureName] = useState<string>("");
  const [newStructueNickName, updateNewStructureNickName] =
    useState<string>("");

  const [createAble, setCreateAble] = useState<boolean>(true);
  const [isCreate, setIsCreate] = useState<boolean>(false);

  const handleDrop = (newTree: NodeModel<StructureInfo>[]) => {
    console.log(newTree);
    const isOverDepth =
      newTree
        .map((item) => getDetph(newTree, item, 0))
        .filter((item) => item > MAX_DEPTH).length > 0;

    if (isOverDepth) {
      return alert("조직도는 그룹 -> 실 -> 팀");
    } else {
      newTree.forEach((item) => {
        if (item.data) {
          item.data.isChange = !item.data.isNew;
          item.data.parent = item.parent;
        }
      });
      console.log(newTree);
      setTreeData([...newTree]);
    }
  };

  const handleTextChange = (
    id: NodeModel["id"],
    type: "originName" | "nickName",
    value: string
  ) => {
    if (type === "originName") {
      const newTree = treeData.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            text: value,
            data: { ...node.data, name: value },
          };
        }

        return node;
      });
      setTreeData(newTree);
    } else {
      const newTree = treeData.map((node) => {
        if (node.id === id && node.data) {
          node.data.nickName = value;
        }

        return node;
      });
      setTreeData(newTree);
    }
  };

  const handleSelect = (node: NodeModel) => {
    const depth = getDetph(treeData, node, 0);
    setCreateAble(depth < 2);
    setSelectedNode(node.id);
    setIsCreate(false);
  };

  const handleDelete = (node: NodeModel) => {
    const deleteIds = [
      node.id,
      ...getDescendants(treeData, node.id).map((item) => item.id),
    ];
    const newTree = treeData.filter((item) => !deleteIds.includes(item.id));

    if (selectedNode === node.id) {
      setSelectedNode(undefined);
    }

    setTreeData(newTree);
  };

  const handleSubmit = (
    newNode: Omit<NodeModel<StructureInfo>, "id">,
    data: StructureInfo
  ) => {
    const lastId = parseInt(`${getLastId(treeData)}`) + 1;
    const newData: NodeModel<StructureInfo> = {
      ...newNode,
      id: lastId,
      data: { ...data, acStructureId: lastId },
    };

    setTreeData([...treeData, newData]);
    setSelectedNode(lastId);
    setIsCreate(false);
  };

  const getDetph = (
    tree: NodeModel[],
    target: NodeModel | undefined,
    depth?: number
  ): number => {
    let _depth = depth || 0;

    if (!target) return _depth;

    if (target.parent === 0) return _depth;

    const newTarget = tree.find((item) => item.id === target.parent);
    return getDetph(tree, newTarget, _depth + 1);
  };

  useEffect(() => {
    const data = structuredClone(structureData);
    structureDataClone.current = data;
    setTreeData(data);
  }, [structureData]);

  return (
    <Container id="structureModal">
      <Header>
        <span className="heading-18-sb">조직도 편집</span>

        <FlexRow
          style={{
            gap: 10,
          }}
        >
          {createAble && (
            <DefaultButton
              className="heading-16-b"
              sizeType="xxsmall"
              buttonType="text"
              buttonColor={"var(--text-03)"}
              onClick={() => {
                setIsCreate(true);
              }}
              text="생성하기"
            />
          )}

          <DefaultButton
            className="heading-14-b"
            sizeType="small"
            buttonType="filled"
            buttonColor="var(--primary-purple)"
            hoverColor="var(--purple-700)"
            disabled={false}
            onClick={() => {
              console.log(treeData);
              console.log(structureDataClone.current);
              if (structureDataClone.current.length > 0) {
                const updatedData: NodeModel<StructureInfo>[] =
                  structureDataClone.current.map(
                    (item: NodeModel<StructureInfo>) => {
                      const target = treeData.find(
                        (data) => data.id === item.id
                      );
                      if (target) {
                        if (target.data) target.data.isChange = true;
                        return target;
                      } else {
                        if (item.data) {
                          item.data.isDelete = true;
                          item.data.structureIndex = 0;
                        }

                        return item;
                      }
                    }
                  );
                const newData = treeData.filter((item) => item.data?.isNew);
                onSubmit([...updatedData, ...newData]);
              } else {
                onSubmit(treeData);
              }
            }}
            text="완료"
          />
        </FlexRow>
      </Header>

      <Body>
        <StructureContainer>
          <Description
            className="caption-11-rg"
            style={{ marginTop: 0, marginBottom: 16 }}
          >
            <div>조직을 지우시려면 속해있는 직원이 없어야 합니다.</div>
            <div>조직을 지우시려면 포함하는 조직이 없어야 합니다.</div>
          </Description>
          <DndProvider backend={MultiBackend} options={getBackendOptions()}>
            <StructureTreeContainer className="scroll__invisible">
              <Tree
                tree={treeData}
                rootId={0}
                render={(node, { depth, isOpen, onToggle }) => (
                  <CustomNode
                    isEditable
                    node={node}
                    depth={depth}
                    isOpen={isOpen}
                    isSelected={node.id === selectedNode}
                    hasChildren={
                      treeData.filter((item) => item.parent === node.id)
                        .length > 0
                    }
                    onToggle={onToggle}
                    onSelect={handleSelect}
                    onDelete={handleDelete}
                  />
                )}
                dragPreviewRender={(monitorProps) => (
                  <CustomDragPreview monitorProps={monitorProps} />
                )}
                onDrop={handleDrop}
                classes={{
                  root: "treeRoot",
                  draggingSource: "draggingSource",
                  placeholder: "placeholderContainer",
                }}
                sort={false}
                insertDroppableFirst={false}
                canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
                  if (dragSource?.parent === dropTargetId) {
                    return true;
                  }
                }}
                dropTargetOffset={5}
                placeholderRender={(node, { depth }) => (
                  <Placeholder node={node} depth={depth} />
                )}
              />
            </StructureTreeContainer>
          </DndProvider>
        </StructureContainer>
        <StructureDetailWrapper>
          {selectedNode || isCreate ? (
            <StructureDetail>
              <div className="heading-16-sb">
                {isCreate ? "조직 생성" : "조직도 정보"}
              </div>
              <Description className="body-14-rg">
                <div>이름은 조직의 고유 이름을 입력해주세요.</div>
                <div>
                  별칭은 긴 고유이름 대신 짧은 길이의 이름을 입력해주세요.
                </div>
                <div>
                  고유 이름은 각종 문서에 사용되고 별칭은 래티스 내에서
                  사용됩니다.
                </div>
                <div>* 단 별칭이 없을 시 고유이름으로 사용됩니다. </div>
              </Description>

              <RowTitleBox
                style={{ marginTop: 24 }}
                title="고유 이름"
                titleWidth={118}
              >
                <InputBox
                  width={332}
                  defaultValue={
                    isCreate
                      ? ""
                      : (
                          treeData.find((item) => item.id === selectedNode)
                            ?.data as StructureInfo
                        ).name
                  }
                  onChange={(value) => {
                    isCreate && updateNewStructureName(value);
                  }}
                  placeholder="이름을 입력해주세요."
                  onBlur={(value) => {
                    if (isCreate) return;
                    if (selectedNode) {
                      handleTextChange(selectedNode, "originName", value);
                    }
                  }}
                />
              </RowTitleBox>

              <RowTitleBox
                style={{ marginTop: 12 }}
                title="별칭"
                titleWidth={118}
              >
                <InputBox
                  width={332}
                  defaultValue={
                    isCreate
                      ? ""
                      : (
                          treeData.find((item) => item.id === selectedNode)
                            ?.data as StructureInfo
                        ).nickName
                  }
                  placeholder="별칭을 입력해주세요."
                  onChange={(value) => {
                    isCreate && updateNewStructureNickName(value);
                  }}
                  onBlur={(value) => {
                    if (selectedNode) {
                      handleTextChange(selectedNode, "nickName", value);
                    }
                  }}
                />
              </RowTitleBox>
              {isCreate && (
                <FlexRow
                  style={{
                    width: "100%",
                    justifyContent: "flex-end",
                    marginTop: "auto",
                    gap: 10,
                  }}
                >
                  <DefaultButton
                    className="heading-14-b"
                    sizeType="xxsmall"
                    buttonType="text"
                    buttonColor={"var(--text-03)"}
                    onClick={() => {
                      setIsCreate(false);
                    }}
                    text="취소"
                  />

                  <DefaultButton
                    className="heading-14-b"
                    sizeType="small"
                    buttonType="filled"
                    buttonColor="var(--primary-purple)"
                    hoverColor="var(--purple-700)"
                    disabled={newStructueName.length === 0}
                    onClick={() => {
                      const newStructue: StructureInfo = {
                        isNew: true,
                        name: newStructueName,
                        nickName: newStructueNickName,
                        parent: 0,
                      };
                      handleSubmit(
                        {
                          text: newStructueName,
                          parent: 0,
                          droppable: true,
                        },
                        newStructue
                      );
                    }}
                    text="생성"
                  />
                </FlexRow>
              )}
            </StructureDetail>
          ) : (
            <EmptyContainer>조직도를 선택해주세요.</EmptyContainer>
          )}
        </StructureDetailWrapper>
      </Body>
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  width: 60vw;
  height: 70vh;
  max-height: 100%;

  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  width: 100%;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  border-bottom: 1px solid var(--gray-300);
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 56px);
  display: flex;
  flex-direction: row;
`;

const StructureContainer = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-right: 1px solid var(--gray-300);
`;

const StructureTreeContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  max-height: 100%;

  ul {
    list-style: none;
  }

  .treeRoot {
    height: 100%;
  }
  .draggingSource {
    opacity: 0.3;
  }
  .placeholderContainer {
    position: relative;
  }
`;
const StructureDetailWrapper = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StructureDetail = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const EmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Description = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 16px;

  background-color: var(--gray-50);
  border-radius: 4px;
  gap: 8px;
  padding: 12px 16px;
  color: var(--text-01);

  border: 1px solid var(--gray-300);
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export default StructureModifyModal;
