import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  getAcIndustryTech,
  getAcPosition,
  getAcResponsibility,
  getAcStructure,
  getAcUser,
  removeResponsibility,
  resigitePosition,
  resigiteResponsibility,
  removePosition,
  updateAcStructure,
} from "../../../../../api/repository/admin/AcceleratorRepository";
import {
  PositionProps,
  ResponsibilityProps,
  StructureHeader,
  StructureHeaderProps,
  StructureInfo,
  StructureTreeProps,
  defaultStructureHeaderList,
} from "../interface/Structure.interface";
import { IndustryTech, UserInfo } from "../../../../../type/data";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { copySuspensePromise } from "jotai/core/suspensePromise";

const useStructure = () => {
  const [headerList, setHeaderList] = useState<StructureHeaderProps[]>(
    defaultStructureHeaderList
  );
  const [selectedHeader, setSelectedHeader] = useState<
    StructureHeader | undefined
  >(undefined);
  const [selectedStructure, setSelectedStructure] = useState<
    NodeModel<StructureInfo> | undefined
  >(undefined);
  const [structureData, setStructureData] = useState<
    NodeModel<StructureInfo>[] | undefined
  >(undefined);
  const [searchValue, setSearchValue] = useState<string>("");
  const prevStructureData = useRef<NodeModel<StructureInfo>[] | undefined>(
    undefined
  );
  const prevAllUser = useRef<UserInfo[] | undefined>(undefined);

  const structureTreeData = useRef<StructureTreeProps[] | undefined>(undefined);
  const [allUser, setAllUser] = useState<UserInfo[] | undefined>(undefined);
  const [allIndustryTech, setAllIndustryTech] = useState<
    IndustryTech[] | undefined
  >(undefined);
  const [allResponsibility, setAllResponsibility] = useState<
    ResponsibilityProps[] | undefined
  >(undefined);
  const [allPosition, setAllPosition] = useState<PositionProps[] | undefined>(
    undefined
  );
  const [filterUser, setFilterUser] = useState<UserInfo[] | undefined>(
    undefined
  );
  const [selectedUserInfo, setSelectedUserInfo] = useState<
    UserInfo | undefined
  >(undefined);

  const [isChange, setIsChange] = useState<boolean | undefined>(undefined);

  const [toggleStructureManage, setToggleStructureManage] =
    useState<boolean>(false);
  const [toggleUserCrete, setToggleUserCreate] = useState<boolean>(false);
  const [toggleResponsibilityManage, setToggleResponsibilityManage] =
    useState<boolean>(false);
  const [togglePositionManage, setTogglePositionManage] =
    useState<boolean>(false);

  const [toggleMovedEmployee, setToggleMovedEmployee] =
    useState<boolean>(false);

  const onClickStructureHeader = (header: StructureHeader) => {
    setSelectedHeader(header);
    setSelectedStructure(undefined);
  };
  const onClickStructure = (target?: NodeModel<StructureInfo>) => {
    setSelectedStructure(target ? target : undefined);
    setSelectedHeader(undefined);
  };

  const openClickStructureManage = () => {
    setToggleStructureManage(true);
  };
  const closeClickStructureManage = () => {
    setToggleStructureManage(false);
  };

  const openClickResponsibilityManage = () => {
    setToggleResponsibilityManage(true);
    closeClickPositionManage();
    closeClickMovedEmployee();
  };
  const closeClickResponsibilityManage = () => {
    setToggleResponsibilityManage(false);
  };

  const openClickPositionManage = () => {
    setTogglePositionManage(true);
    closeClickResponsibilityManage();
    closeClickMovedEmployee();
  };
  const closeClickPositionManage = () => {
    setTogglePositionManage(false);
  };

  const openClickMovedEmployee = () => {
    setToggleMovedEmployee(true);
    closeClickPositionManage();
    closeClickResponsibilityManage;
  };
  const closeClickMovedEmployee = () => {
    setToggleMovedEmployee(false);
  };

  const onClickUserCard = (userInfo: UserInfo) => {
    setSelectedUserInfo(userInfo);
    setToggleUserCreate(true);
  };

  const closeUserCreate = () => {
    setSelectedUserInfo(undefined);
    setToggleUserCreate(false);
  };

  const createResponsibility = async (name: string) => {
    try {
      const result = await resigiteResponsibility(name);
      if (result) {
        getResponsibility();
        toast.success(`새로운 직책 ${name}을 생성하였습니다.`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {}
  };
  const deleteResponsibility = async (target: ResponsibilityProps) => {
    try {
      const result = await removeResponsibility(
        target.responsibility.acResponsibilityId
      );
      if (result) {
        getResponsibility();
        toast.success(`${target.responsibility.name} 직책을 삭제하였습니다.`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {}
  };

  const createPositoin = async (name: string) => {
    try {
      const result = await resigitePosition(name);
      if (result) {
        getPosition();
        toast.success(`새로운 직급 ${name}을 생성하였습니다.`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {}
  };
  const deletePosition = async (target: PositionProps) => {
    try {
      const result = await removePosition(target.position.acPositionId);
      if (result) {
        getPosition();
        toast.success(`${target.position.name} 직급을 삭제하였습니다.`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {}
  };

  // DFS 탐색 함수
  const dfs = (node: StructureTreeProps, target: StructureInfo) => {
    if (node.info.acStructureId === target.parent) {
      const index =
        (node.info.structureIndex || 0) * 10 + (node.children.length + 1);
      node.info.depth?.push(target.acStructureId);
      return (
        target &&
        node.children.push({
          info: { ...target, structureIndex: index },
          children: [],
        })
      );
    }

    node.children.forEach((child, index) => dfs(child, target));
  };

  // DFS 탐색 함수
  const treeIterator = (node: StructureTreeProps, result: any = []) => {
    if (!node) return;

    // 현재 노드 방문 (부모 노드 처리)
    result.push(node.info);

    // 모든 자식 노드에 대해 재귀적으로 DFS 수행
    node.children.forEach((child) => treeIterator(child, result));

    return result;
  };

  const collectDescendantIds = (
    node: StructureTreeProps
  ): (number | string)[] => {
    let allDescendantIds: (number | string)[] = []; // 현재 노드의 모든 하위 노드의 ID를 저장할 배열

    node.children.forEach((child) => {
      child.info.acStructureId &&
        allDescendantIds.push(child.info.acStructureId); // 직접적인 자식 노드의 ID 추가
      const childDescendantIds = collectDescendantIds(child); // 재귀적으로 자식 노드의 모든 하위 노드 ID 수집
      allDescendantIds = allDescendantIds.concat(childDescendantIds); // 수집된 하위 노드 ID를 현재 목록에 추가
    });

    // 현재 노드에 모든 하위 노드의 ID 목록 저장
    node.info.depth = [node.info.acStructureId, ...allDescendantIds];
    return allDescendantIds; // 현재 노드의 모든 하위 노드의 ID 목록 반환
  };

  const transformStructure = (structure: StructureInfo[], user: UserInfo[]) => {
    const structureTree: StructureTreeProps[] = [];

    const newHeaderList = headerList.map((header) => {
      let count = 0;
      switch (header.type) {
        case "NotAuthority":
          count = user.filter((item) => !item.acStructureId).length;
          break;
        case "BoardMember":
          count = user.filter((item) => item.isBoardMember).length;
          break;
        case "Quitter":
          count = user.filter(
            (item) =>
              item.employmentStatusId && [4].includes(item.employmentStatusId)
          ).length;
          break;
        case "AllMembers":
          count = user.filter(
            (item) =>
              item.acStructureId &&
              item.employmentStatusId &&
              [2, 3].includes(item.employmentStatusId)
          ).length;
          break;
        default:
          count = 0;
          break;
      }
      return {
        ...header,
        count: count,
      };
    });

    structure
      .filter((item) => item.parent === 0)
      .forEach((item, index) => {
        if (item) {
          structureTree.push({
            info: { ...item, structureIndex: index + 1 },
            children: [],
          });
        }
      });

    structure
      .filter((item) => item.parent !== 0)
      .forEach((item) => {
        structureTree.forEach((tree) => {
          dfs(tree, item);
        });
      });

    structureTree.forEach((item) => {
      collectDescendantIds(item);
    });

    console.log(structureTree);

    let data: StructureInfo[] = [];
    structureTree.forEach((item) => {
      data = [...data, ...treeIterator(item)];
    });

    console.log(data);

    const newData: NodeModel<StructureInfo>[] = data.map(
      (item): NodeModel<StructureInfo> => {
        return {
          id: item.acStructureId || 0,
          text: item.name,
          droppable: true,
          parent: item.parent || 0,
          data: {
            ...item,
            userCount: user.filter((userinfo) => {
              return (
                userinfo.employmentStatusId &&
                [2, 3].includes(userinfo.employmentStatusId) &&
                item.depth?.includes(userinfo.acStructureId)
              );
            }).length,
          },
        };
      }
    );
    console.log(newHeaderList);
    setHeaderList([...newHeaderList]);
    setStructureData(newData);
    setAllUser([...user]);

    return newData;
  };

  const onModifyUserInfo = (newUserData: UserInfo) => {
    if (!allUser) return;

    const newAllUserData = allUser.map((item) => {
      if (item.id === newUserData.id) {
        return { ...newUserData };
      }
      return item;
    });

    return newAllUserData;
  };

  const onMoveStructure = (users: UserInfo[]) => {
    if (!selectedStructure || !allUser || !structureData) return;

    const newStructure: StructureInfo[] = [];
    const selectedUserIds = users.map((item) => item.id);
    const newAllUserData = allUser.map((item) => {
      if (selectedUserIds.includes(item.id)) {
        return { ...item, acStructureId: selectedStructure.id };
      }
      return item;
    });

    structureData.forEach((item) => item.data && newStructure.push(item.data));

    if (newStructure && newAllUserData) {
      transformStructure(newStructure, newAllUserData || []);
      filteringUser(newAllUserData);
      closeClickMovedEmployee();
    }
  };

  const getStructureInit = async () => {
    try {
      const structureResult = await getAcStructure();
      const userResult = await getAcUser();
      const industryResult = await getAcIndustryTech();

      if (structureResult && userResult) {
        const result = transformStructure(structureResult, userResult);
        result && result.length > 0 && setSelectedStructure(result[0]);
        prevStructureData.current = structuredClone(result);
        prevAllUser.current = structuredClone(userResult);
      }
      if (industryResult) {
        setAllIndustryTech(industryResult.filter((item) => item.isActive));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filteringUser = (data?: UserInfo[]) => {
    const users = data ? data : allUser;

    if (selectedStructure && users) {
      setFilterUser([
        ...users.filter(
          (item) =>
            item.employmentStatusId &&
            [2, 3].includes(item.employmentStatusId) &&
            selectedStructure.data &&
            selectedStructure.data.depth?.includes(item.acStructureId)
        ),
      ]);
    } else if (selectedHeader && users) {
      let filterUser: UserInfo[] = [];
      switch (selectedHeader) {
        case "NotAuthority":
          filterUser = users.filter((item) => !item.acStructureId);
          break;
        case "BoardMember":
          filterUser = users.filter((item) => item.isBoardMember);
          break;
        case "Quitter":
          filterUser = users.filter(
            (item) =>
              item.employmentStatusId && [4].includes(item.employmentStatusId)
          );
          break;
        case "AllMembers":
          filterUser = users.filter((item) => {
            return (
              item.acStructureId &&
              item.employmentStatusId &&
              [2, 3].includes(item.employmentStatusId)
            );
          });
          break;
      }

      setFilterUser([
        ...filterUser.filter((item) => {
          return (
            item.name
              .replaceAll(" ", "")
              .toUpperCase()
              .includes(searchValue.replaceAll(" ", "").toUpperCase()) ||
            item.email
              ?.replaceAll(" ", "")
              .toUpperCase()
              .includes(searchValue.replaceAll(" ", "").toUpperCase()) ||
            item.phoneNumber
              ?.replaceAll(" ", "")
              .replaceAll("-", "")
              .toUpperCase()
              .includes(
                searchValue
                  .replaceAll(" ", "")
                  .replaceAll("-", "")
                  .toUpperCase()
              ) ||
            item.acStructure?.name
              ?.replaceAll(" ", "")
              .toUpperCase()
              .includes(searchValue.replaceAll(" ", "").toUpperCase()) ||
            item.acPosition?.name
              ?.replaceAll(" ", "")
              .toUpperCase()
              .includes(searchValue.replaceAll(" ", "").toUpperCase()) ||
            item.acResponsibility?.name
              ?.replaceAll(" ", "")
              .toUpperCase()
              .includes(searchValue.replaceAll(" ", "").toUpperCase())
          );
        }),
      ]);
    } else {
      setFilterUser([]);
    }
  };

  const getResponsibility = async () => {
    const responsibilityResult = await getAcResponsibility();
    console.log(responsibilityResult);
    if (responsibilityResult) {
      const newData = responsibilityResult.map((item): ResponsibilityProps => {
        return {
          responsibility: item,
          isUsed: allUser
            ? allUser.filter(
                (user) => user.acResponsibilityId === item.acResponsibilityId
              ).length > 0
            : false,
        };
      });
      setAllResponsibility(newData);
    }
  };

  const getPosition = async () => {
    const positionResult = await getAcPosition();
    if (positionResult) {
      const newData = positionResult.map((item): PositionProps => {
        return {
          position: item,
          isUsed: allUser
            ? allUser.filter((user) => user.acPositionId === item.acPositionId)
                .length > 0
            : false,
        };
      });
      setAllPosition(newData);
    }
  };

  const onUpdateStructure = async () => {
    const getStructure = () => {
      if (structureData === undefined) return [];

      structureData
        .filter((item) => item.parent === 0)
        .forEach((item, index) => {
          if (item.data) item.data.structureIndex = index + 1;
        });

      structureData.forEach((parent) => {
        structureData
          .filter((item) => item.parent === parent.id)
          .forEach((item, index) => {
            if (item.data)
              item.data.structureIndex =
                (parent.data?.structureIndex || 0) * 10 + index + 1;
          });
      });

      console.log(structureData);

      return structureData.map((item, index): StructureInfo => {
        return {
          isNew: item.data?.isNew,
          isChange: item.data?.isChange,
          isDelete: item.data?.isDelete || false,
          acStructureId: item.data?.acStructureId,
          name: item.data?.name || "untitled",
          nickName: item.data?.nickName,
          parent: item.data?.parent,
          structureIndex: item.data?.structureIndex,
        };
      });
    };
    const updateData = {
      structure: getStructure(),
      users: allUser,
    };
    console.log(updateData);

    const result = await updateAcStructure(updateData);
    console.log(result);

    if (result) {
      toast.success("저장성공", { position: toast.POSITION.BOTTOM_CENTER });
      getStructureInit();
      setIsChange(false);
      setSelectedHeader(undefined);
      setSearchValue("");
    }
  };

  const isCheckDataChagne = () => {
    setIsChange(true);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    getStructureInit();
  }, []);

  useEffect(() => {
    getResponsibility();
    getPosition();
  }, [allUser]);

  useEffect(() => {
    if (searchValue.length > 0) {
      if (
        !(
          selectedHeader &&
          ["NotAuthority", "BoardMember", "Quitter"].includes(selectedHeader)
        )
      ) {
        onClickStructureHeader("AllMembers");
      }
    }

    filteringUser();
  }, [searchValue]);

  useEffect(() => {
    filteringUser();
  }, [selectedStructure, selectedHeader]);

  return [
    structureData,
    headerList,
    selectedHeader,
    selectedStructure,
    allUser,
    allIndustryTech,
    allResponsibility,
    allPosition,
    filterUser,
    selectedUserInfo,
    toggleUserCrete,
    toggleResponsibilityManage,
    togglePositionManage,
    toggleMovedEmployee,
    toggleStructureManage,
    isChange,
    searchValue,
    setSearchValue,
    onClickStructure,
    transformStructure,
    filteringUser,
    onClickUserCard,
    closeUserCreate,
    openClickResponsibilityManage,
    closeClickResponsibilityManage,
    openClickPositionManage,
    closeClickPositionManage,
    openClickMovedEmployee,
    closeClickMovedEmployee,
    openClickStructureManage,
    closeClickStructureManage,
    createResponsibility,
    deleteResponsibility,
    createPositoin,
    deletePosition,
    onClickStructureHeader,
    onModifyUserInfo,
    onUpdateStructure,
    isCheckDataChagne,
    onMoveStructure,
  ] as const;
};

export default useStructure;
